import React, { useEffect } from "react";
import useQRCodeGenerator from 'react-hook-qrcode-svg';
import { Document, Page, View, Text, Link, StyleSheet, Svg, Rect, Path, G, Font, Image } from '@react-pdf/renderer';
import { isSameMonth, isSameDay, format } from "date-fns";
import locale from "date-fns/locale/pt-BR";

import { altFonts, images } from "../../../../constants";
import formatCPF from "../../../../utils/formatCpf";

const Certificate2024 = ({certificate}) => {
    const QRCODE_LEVEL = 'H';
    const QRCODE_BORDER = 2;

    const { path, viewBox } = useQRCodeGenerator(
        `${window.location.origin}/valida/${certificate.idCertificate}`,
        QRCODE_LEVEL,
        QRCODE_BORDER
      );

    Font.register({
        family: 'ShareTechMono',
        src: altFonts.ShareTechMonoRegular,
        fontWeight: 400,
        fontStyle: "normal"
    });

    Font.register({
        family: 'MontserratLight',
        src: altFonts.MontserratLight,
        fontWeight: 300,
        fontStyle: "normal"
    });

    Font.register({
        family: 'MontserratMedium',
        src: altFonts.MontserratMedium,
        fontWeight: 400,
        fontStyle: "normal"
    });

    Font.register({
        family: 'MontserratSemiBold',
        src: altFonts.MontserratSemiBold,
        fontWeight: 600,
        fontStyle: "normal"
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
        },
        content: {
            margin: "1cm",
            flexDirection: 'column',
        },
        ViewDados1:{
            width: "440pt",
            height: "305pt",
            position: "absolute",
            top: "-2pt",
            left: "230pt"
        },
        ViewInfo:{
            width: "440pt",
            marginTop: "12pt",
            flexDirection: 'column',
        },
        ViewInfoRow:{
            width: "440pt",
            marginTop: "15pt",
            flexDirection: 'row',
        },
        TextDados:{
            fontFamily: "MontserratLight",
            fontSize: "20pt",
            fontWeight: 300,
        },
        TextNome:{
            fontFamily: "MontserratMedium",
            fontSize: "24pt",
            fontWeight: 500,
            width: "440pt",
            maxHeight: "61pt",
        },
        TextCPF:{
            fontFamily: "MontserratMedium",
            fontSize: "18pt",
            fontWeight: 500,
            width: "440pt",
        },
        TextCurso:{
            fontFamily: "MontserratLight",
            fontSize: "20pt",
            fontWeight: 300,
        },
        TextTitleCurso:{
            fontFamily: "MontserratSemiBold",
            fontSize: "18pt",
            fontWeight: 600,
            maxHeight: "46pt",
        },
        TextPeriodo:{
            fontFamily: "MontserratLight",
            fontSize: "20pt",
            fontWeight: 300,
        },
        TextDataPeriodo:{
            fontFamily: "MontserratSemiBold",
            fontSize: "18pt",
            fontWeight: 600,
        },
        TextCarga:{
            fontFamily: "MontserratLight",
            fontSize: "20pt",
            fontWeight: 300,
        },
        TextCargaBold:{
            fontFamily: "MontserratSemiBold",
            fontSize: "18pt",
            fontWeight: 600,
        },
        ViewLocalData:{
            fontFamily: "MontserratMedium",
            fontSize: "18pt",
            fontWeight: 500,
            width: "440pt",
            position: "absolute",
            top: "310pt",
            left: "230pt"
        },
        ViewInformacao:{
            position: "absolute",
            left: "230pt",
            top: "445pt",
            fontFamily: "MontserratSemiBold",
            fontSize: "10pt",
            fontWeight: 600,
        },
        ViewInformacaoArea:{
            position: "absolute",
            flexDirection: 'row',
            left: "230pt",
            top: "470pt",
            maxWidth: "455pt",
        },
        ViewInformacaoIncial:{
            marginRight:"5pt",
        },
        ViewInformacaoNext:{
            marginRight:"5pt",
            paddingLeft:"10pt",
            borderLeft: "2pt solid Black",
        },
        ViewInfoComplementar:{
            flexDirection: 'row',
        },
        TextInfo:{
            fontFamily: "MontserratLight",
            fontSize: "9pt",
            fontWeight: 300,
        },
        TextInfoBold:{
            fontFamily: "MontserratSemiBold",
            fontSize: "9pt",
            fontWeight: 600,
        },
        Viewvalida:{
            position: "absolute",
            left: "230pt",
            top: "516pt",
        },
        ViewvalidaCodigo:{
            position: "absolute",
            right: "9pt",
            top: "526pt",
        },
        TextValida:{
            fontFamily: "MontserratLight",
            fontSize: "8pt",
            fontWeight: 300,
        },
        TextValidaLink:{
            fontFamily: "MontserratMedium",
            fontSize: "8pt",
            fontWeight: 500,
            color: "black",
            textDecoration: "none",
        },
        TextValidaCodigo:{
            fontFamily: "ShareTechMono",
            fontSize: "10pt",
            fontWeight: 400,
        },
        ViewQrCodigo:{
            position: "absolute",
            top: "444pt",
            left: "695pt"
        },
    });

    useEffect(() => {
        console.log("Dados certificados",certificate);
        // eslint-disable-next-line
      }, []);

    return (
        <Document author="Canal pedagógico" creator="Canal pedagógico" producer="Canal pedagógico" title={`${certificate.name.trim()} - ${certificate.courseTitle.trim()}`}>
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.content}>
              <Image src={images.certificadoPng_Rgb_logo}/>
              <View style={styles.ViewDados1}>
                <View style={styles.ViewInfo}>
                  <Text style={styles.TextDados}>Certificamos que</Text>
                  <Text style={styles.TextNome}>{certificate.name.trim()}</Text>
                  <Text style={styles.TextCPF}>{formatCPF(certificate.cpf)}</Text>
                </View>
                <View style={styles.ViewInfo}>
                  <Text style={styles.TextCurso}>participou do curso</Text>
                  <Text style={styles.TextTitleCurso}>{certificate.courseTitle.trim()}</Text>
                </View>
                <View style={styles.ViewInfo}>
                  <Text style={styles.TextPeriodo}>realizado no período de</Text>
                  <Text style={styles.TextDataPeriodo}>{isSameMonth(new Date(certificate.courseBegin),new Date(certificate.courseEnd))?(isSameDay(new Date(certificate.courseBegin),new Date(certificate.courseEnd))?
                    format(new Date(certificate.courseBegin), "dd 'de' MMMM 'de' yyyy", {locale}):
                    `${format(new Date(certificate.courseBegin), "dd", {locale})} a ${format(new Date(certificate.courseEnd), "dd 'de' MMMM 'de' yyyy", {locale})}`):
                    `${format(new Date(certificate.courseBegin), "dd 'de' MMMM", {locale})} a ${format(new Date(certificate.courseEnd), "dd 'de' MMMM 'de' yyyy", {locale})}`}</Text>
                </View>
                <View style={styles.ViewInfoRow}>
                  <Text style={styles.TextCarga}>com carga horária de </Text>
                  <Text style={styles.TextCargaBold}>{certificate.totalWorkload}</Text>
                  <Text style={styles.TextCarga}> horas</Text>
                </View>
              </View>
              <View style={styles.ViewLocalData}>
                <Text>Mogi das Cruzes, {format(new Date(certificate.createdAt), "dd 'de' MMMM 'de' yyyy", {locale})}</Text>
              </View>
    
              <View style={styles.ViewInformacao}>
                <Text>Informações Complementares</Text>
              </View>
    
              <View style={styles.ViewInformacaoArea}>
                <View style={styles.ViewInformacaoIncial}>
                  <View style={styles.ViewInfoComplementar}>
                    <Text style={styles.TextInfo}>Carga Horária Total: </Text>  
                    <Text style={styles.TextInfoBold}>{certificate.totalWorkload}</Text>  
                    <Text style={styles.TextInfo}> h</Text>  
                  </View>
                  <View style={styles.ViewInfoComplementar}>
                    <Text style={styles.TextInfo}>Carga Horária Realizada: </Text>  
                    <Text style={styles.TextInfoBold}>{certificate.hoursTaken}</Text>  
                    <Text style={styles.TextInfo}> h</Text>  
                  </View>
                  <View style={styles.ViewInfoComplementar}>
                    <Text style={styles.TextInfo}>Abonadas (HTPF): </Text>  
                    <Text style={styles.TextInfoBold}>{certificate.hoursJustified}</Text>  
                    <Text style={styles.TextInfo}> h</Text>  
                  </View>
                </View>
                {certificate.htpf && <View style={styles.ViewInformacaoNext}>
                  <View style={styles.ViewInfoComplementar}>
                    <Text style={styles.TextInfo}>Formação elementar (lançado pelo DEPED)</Text>  
                  </View>
                </View>}
              </View>
    
              <View style={styles.Viewvalida}>
                <Text style={styles.TextValida}>Para validar esse certificado, escaneie o QRCode ou entre em:</Text>
                <Link src={`${window.location.origin}/valida/${certificate.idCertificate}`} style={styles.TextValidaLink}>{window.location.origin}/valida</Link>
              </View>
              <View style={styles.ViewvalidaCodigo}>
                <Text style={styles.TextValidaCodigo}>{certificate.idCertificate}</Text>
              </View>
    
              <View style={styles.ViewQrCodigo}>
                <Svg
                  width={80}
                  height={80}
                  viewBox={viewBox}
                  stroke='none'
                >
                  <Rect width='100%' height='100%' fill='#ffffff' />
                  <Path d={path} fill='#000000' strokeWidth={0.1}/>
                </Svg>
              </View>
            </View>
          </Page>
        </Document>
    );
};

export default Certificate2024;