import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";
import * as XLSX from "xlsx";

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton";
import InputText from "../../components/UI/Inputs/InputText";
import Modal from "../../components/UI/Modal";

import {
  Container,
  ListContent,
  ListItem,
  Justify,
  ButtonBox,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
// import { images } from "../../constants";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";


const CoursesCertificateManager = () => {
  const [coursesList, setCoursesList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedList, setSelectedList] = useState("");
  const [openList, setOpenList] = useState(false);

  const [modalJustify, setModalJustify] = useState({open:false,info:{}});
  const [modalResponse, setModalResponse] = useState({open:false,text:"",info:{},confirm:false});

  const [justify, setJustify] = useState("");
  const [justifyCheck, setJustifyCheck] = useState(false);
  const { accessRight } = useAuth();

  const [year, setYear] = useState(null);
  const [listYear, setListYear] = useState([2024,2025]);
  const [alert, setAlert] = useState(null);

  const navigate = useNavigate();

  const [getCourses, getCoursesInfo] = useApi({
    url: "canalPedagogico/course/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setCoursesList(response.data);
        setAlert(null);
        if(year!==null && response.data.length===0){
          setAlert(`Não foi localizado certificados para serem emitidos em ${year}`);
        }
      }
    },
  });

  const [getCoursesSubscription, getCoursesSubscriptionInfo] = useApi({
    url: "canalPedagogico/course/subscription/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setSubscriptionList(response.data);
      }
    },
  });

  const [createCertificate, createCertificateInfo] = useApi({
    url: "canalPedagogico/course/certificate/",
    method: "post",
    onCompleted: (response) => {
      if (!response.error) {
        setModalResponse({open:true,text:"Certificado criado com sucesso",info:{},confirm:false});
        getCoursesSubscription({params:{id:selectedList.id}});
      }
    },
    onError: (error)=>{
      setModalResponse({open:true,text:"Erro ao criar o certificado",info:{},confirm:false});
    }
  });

  const [cancelCertificate, cancelCertificateInfo] = useApi({
    url: "canalPedagogico/course/certificate/",
    method: "put",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("cancelando",response.data);
        setModalResponse({open:true,text:"Certificado Cancelado",info:{},confirm:false});
        getCoursesSubscription({params:{id:selectedList.id}});
      }
    },
    onError: (error)=>{
      setModalResponse({open:true,text:"Erro ao Cancelar o certificado tentar novamente?",info:modalResponse.info,confirm:true});
    }
  });

  const create = (data)=>{
    createCertificate({data})
  };

  const cancel = (data)=>{
    if(data.reasonForCancelattion.trim().length>=10){
      cancelCertificate({data});
    }
  };

  const controlList = (typeChange=0, segmentChange=0)=>{
    let list = [];
    coursesList.forEach((type)=>{
      if(segmentChange!==0){
        if(type.courseType===typeChange){
          type.segments.forEach((segment)=>{
            if(segment.segment===segmentChange){
              if(segment.open){
                segment.open=false;
              }
              else{
                segment.open=true;
              }
            }
          })
        }
      }
      else{
        if(type.courseType===typeChange){
          if(type.open){
            type.open=false;
          }
          else{
            type.open=true;
          }
        }
      }
      list.push(type);
    })
    setCoursesList(list);
  };

  const open = (id,title) => {
    setOpenList(true);
    setSelectedList({id,title});
    getCoursesSubscription({params:{id}});
  }

  useEffect(() => {
    //getCourses();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(year!==null){
      getCourses({params:{hasSubscription:true, year}});
    }
    //eslint-disable-next-line
  }, [year]);

  useEffect(() => {
    if(justify.trim().length>=10){
      setJustifyCheck(true);
    }
    else{
      setJustifyCheck(false);
    }
    //eslint-disable-next-line
  }, [justify]);

  return (
    <Container>
      {(getCoursesInfo.loading || 
        createCertificateInfo.loading ||
        cancelCertificateInfo.loading ||
        getCoursesSubscriptionInfo.loading) && <FullScreenLoading />}
      
      <Accessibility />
      <Menu />
      <Banner>GERENCIAMENTO DE CERTIFICADOS DOS CURSOS</Banner>
      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate("/formacoes/manager");}}
        >
          <MdArrowLeft fontSize={36}/> Voltar para gestão de formação
        </ListItem>

        {
          year===null && <>
            {listYear.map((item)=>(
              <ListItem row={true} border={true} key={item} onClick={()=>{setYear(item)}} visible={true} reduct={0} roundborder={true} selected={false}>
                {item}
              </ListItem>
            ))}
          </>
        }

        {
          alert && <>{alert}</>
        }

        {
          coursesList.map((type)=>(type.segments.length>0 &&
            <ListContent key={type.courseType} visible={!openList}>
              <ListItem key={type.courseType} onClick={()=>{controlList(type.courseType)}} visible={true} reduct={0} roundborder={true} selected={type.open}>{type.courseTypeName} {type.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
              {type.segments.map((segment)=>(
                <ListContent visible={type.open} key={segment.segment}>
                  {type.courseTypeName!=="Formação Complementar" && <ListItem key={segment.segment} onClick={()=>{controlList(type.courseType,segment.segment)}} visible={type.open} reduct={2} roundborder={true} selected={segment.open}>{segment.segmentName===null?"OUTROS":segment.segmentName} {segment.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>}
                  {segment.courses.map((course)=>(
                    <ListItem 
                      key={course.id} 
                      visible={(type.courseTypeName==="Formação Complementar")?type.open:segment.open} 
                      reduct={type.courseTypeName==="Formação Complementar"?2:4} 
                      roundborder={true}
                      onClick={()=>{open(course.id,course.title); window.scrollTo(0, 0);}}
                      >
                        {course.title}
                    </ListItem>
                  ))}
                </ListContent>))}
            </ListContent>))
        }

          <ListItem key={"Vizualizacao de lista"} onClick={()=>{setOpenList(false)}} visible={openList} reduct={0} roundborder={true} selected={true}> 
            {selectedList.title} <MdArrowDropUp fontSize={36}/>
          </ListItem>
          <ListContent visible={openList}>
            {accessRight("certificates").SP_rights.can_generate && <ReadMoreButton onClick={()=>{create({idContent:selectedList.id})}} text={"Gerar Certificado para a turma"} disabled={false}/>}
          </ListContent>
          <ListContent visible={openList}>
            {subscriptionList.map((subscription)=>(
              <ListItem key={subscription.idUserSubscription} visible={openList} reduct={2} disablePointer={true}> 
                <div>
                  {subscription.name} (RGF:{subscription.rgf})
                  <div className="buttonList">
                    {subscription.idcertificate?
                      <>
                        <ReadMoreButton onClick={()=>{navigate(`/coursecertificate/${subscription.idcertificate}`)}} text={"Exibir Certificado"} disabled={false}/>
                        {accessRight("certificates").SP_rights.can_cancel && <ReadMoreButton onClick={()=>{setModalJustify({open:true,info:subscription});}} text={"Cancelar Certificado"} disabled={false}/>}
                      </>:
                      subscription.hasCertificatePrerequisites?
                      (accessRight("certificates").SP_rights.can_generate && <ReadMoreButton onClick={()=>{create({idUserSubscription:subscription.idUserSubscription})}} text={"Gerar Certificado"} disabled={false}/>):
                      "Não atende aos pré-requisitos para a emissão do certificado"}
                  </div>
                </div>
              </ListItem>
            ))}
          </ListContent>
        <Modal open={modalJustify.open} buttonClose={false}>
          <Justify>
            <InputText
              placeholder="Motivo do cancelamento do certificado (min. 10 characteres)"
              columns={90}
              onChange={(temp)=>{setJustify(temp.target.value);}}
              value={justify}
            />
          </Justify>
          <ButtonBox>
            <ReadMoreButton
              text="Fechar"
              type="button"
              onClick={()=>{
                setModalJustify({open:false,info:{}});
                setJustify("");
              }}
              disabled={false}
            />
            <ReadMoreButton
              text="Cancelar Certificado"
              type="button"
              onClick={()=>{
                setModalResponse({open:true,text:"Confirma o cancelamento do certificado?",info:modalJustify.info,confirm:true});
                setModalJustify({open:false,info:modalJustify.info});
              }}
              disabled={!justifyCheck}
            />
          </ButtonBox>
        </Modal>

        <Modal open={modalResponse.open} buttonClose={false}>
          {modalResponse.text}
          <br/>
          {modalResponse.confirm?
            <Justify>
              Nome: {modalResponse.info.name}<br/>
              Rgf: {modalResponse.info.rgf}<br/>
              Curso: {selectedList.title}<br/>
              Motivo: {justify}<br/><br/>
              <ButtonBox>
                <ReadMoreButton
                  text="Cancelar"
                  type="button"
                  onClick={()=>{
                    setModalResponse({open:false,info:{},confirm:false});
                    setModalJustify({open:false,info:{}});
                    setJustify("");
                  }}
                />
                <ReadMoreButton
                  text="Confirmar"
                  type="button"
                  onClick={()=>{
                    cancel({idUserSubscription:modalResponse.info.idUserSubscription,reasonForCancelattion:justify})
                  }}
                  disabled={!justifyCheck}
                />
              </ButtonBox>
            </Justify>:
            <ButtonBox>
              <ReadMoreButton
                text="Ok"
                type="button"
                onClick={()=>{
                  setModalResponse({open:false,text:"",info:{},confirm:false});
                  setModalJustify({open:false,info:{}});
                  setJustify("");
                }}
                disabled={false}
              />
            </ButtonBox>
          }
        </Modal>
          
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesCertificateManager;