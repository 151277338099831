import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import locale from "date-fns/locale/pt-BR";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";

import { FaSortAmountDownAlt, FaSortAmountDown } from "react-icons/fa";

import * as XLSX from "xlsx";

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton";
import Modal from "../../components/UI/Modal"

import {
  Container,
  ListContent,
  ButtonBox,
  ListItem,
  TableHtpf,
  Message,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
// import { images } from "../../constants";
import { useAuth } from "../../hooks/useAuth";

import useApi from "../../hooks/useApi";


const CoursesHtpfDetail = () => {
  const [htpfDetail, setHtpfDetail] = useState([]);
  const [modal, setModal] = useState({open:false,text:"",confirm:false,process:()=>{}});
  const [sortType, setSortType] = useState({type:"rgf",order:1});
  const navigate = useNavigate();
  const { id } = useParams();
  const { accessRight } = useAuth();
  const [htpfPreview, setHtpfPreview] = useState([]);

  const [getHtpf, getHtpfInfo] = useApi({
    url: "canalPedagogico/htpf/details",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("HTPF", response.data);
        if(response.data[0]){
          setHtpfDetail(response.data[0]);
        }
      }
    },
    onError: ()=>{},
  });

  const [getHtpfPreview, getHtpfPreviewInfo] = useApi({
    url: "canalPedagogico/htpf/preview",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("HTPF", response.data);
        response.data.forEach(element => {
          if(element.hasOpenCourse){
            console.log("Open Course", element);
          }
        });
        if(response.data){
          setHtpfDetail({data:response.data});
        }
      }
    },
    onError: ()=>{
    },
  });

  const [getHtpfPreview2, getHtpfPreviewInfo2] = useApi({
    url: "canalPedagogico/htpf/preview",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("HTPF", response.data);
        response.data.forEach(element => {
          if(element.hasOpenCourse){
            console.log("Open Course", element);
          }
        });
        if(response.data){
          setHtpfPreview({data:response.data});
        }
      }
    },
    onError: ()=>{
    },
  });

  const [gerarHtpf, getGerarHtpfInfo] = useApi({
    url: "canalPedagogico/htpf",
    method: "post",
    onCompleted: (response) => {
      setModal({open:true,
        text:"HTPF gerado com sucesso! Aguardando aprovação para procesamento",
        confirm:false,
        process:()=>{
          navigate("/htpf");
        }});
    },
    onError: ()=>{
      setModal({open:true,
        text:"Erro ao gerar o registro do HTPF tente novamente",
        confirm:false,
        process:()=>{setModal({open:false,text:"",confirm:false,process:()=>{}})}});
    },
  });

  const [deletarHtpf, getDeletarHtpfInfo] = useApi({
    url: "canalPedagogico/htpf",
    method: "delete",
    onCompleted: (response) => {
      setModal({open:true,
        text:"HTPF deletado com sucesso",
        confirm:false,
        process:()=>{navigate("/htpf");}});
    },
    onError: ()=>{
      setModal({open:true,
        text:"Erro ao deletar o registro do HTPF tente novamente",
        confirm:false,
        process:()=>{setModal({open:false,text:"",confirm:false,process:()=>{}})}});
    },
  });

  const [aprovarHtpf, getAprovarHtpfInfo] = useApi({
    url: "canalPedagogico/htpf",
    method: "put",
    onCompleted: (response) => {
      setModal({open:true,
                text:"HTPF aprovado com sucesso o processamento será realizado na noite da aprovaçao do htpf",
                confirm:false,
                process:()=>{navigate("/htpf");}});
    },
    onError: ()=>{
      setModal({open:true,
        text:"Erro ao aprovar o HTPF para processamento tente novamente",
        confirm:false,
        process:()=>{setModal({open:false,text:"",confirm:false,process:()=>{}})}});
    },
  });

  useEffect(() => {
    // console.log(id);
    if(id){
      getHtpf({params:{idhtpf:id}});
      getHtpfPreview2();
    }
    else{
      getHtpfPreview();
    }
    //eslint-disable-next-line
  }, []);

  const sortArrayRgf = (a,b) => {
    let x = a.rgf;
    let y = b.rgf;
    if(x<y){return -1;}
    if(x>y){return 1;}
    return 0;
  }

  const sortArrayEspec = (a,b) => {
    let x,y;
    switch (sortType.type) {
      case "courses":
        x = a.courses[0].title.toLowerCase();
        y = b.courses[0].title.toLowerCase();
        break;
      
      case "name":
        x = a.name.toLowerCase();
        y = b.name.toLowerCase();
        break;
      
      case "workload_completed":
        x = a.workload_completed;
        y = b.workload_completed;
        break;
      
      case "justified_workload":
        x = a.justified_workload;
        y = b.justified_workload;
        break;

      case "total_workload":
        x = a.total_workload;
        y = b.total_workload;
        break;

      case "processed":
        x = a.processedAt;
        y = b.processedAt;
        break;
      
      default:
        x = a.rgf;
        y = b.rgf;
    }
    
    if(x<y){return -1*sortType.order;}
    if(x>y){return 1*sortType.order;}
    return 0;
  }

  return (
    <Container>
      {(getHtpfInfo.loading ||
        getHtpfPreviewInfo.loading ||
        getGerarHtpfInfo.loading ||
        getDeletarHtpfInfo.loading ||
        getAprovarHtpfInfo.loading
      ) && <FullScreenLoading />}
      
      <Accessibility />
      <Menu />
      <Banner>{id?"DETALHES DO HTPF":"PRÉ-VISUALIZAÇÃO DO HTPF"}</Banner>
      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate("/htpf");}}
        >
          <MdArrowLeft fontSize={36}/> Voltar
        </ListItem>

        <TableHtpf>
          <thead>
            <tr key="headTable">
              <th className="number"></th>
              <th className="rgf" onClick={()=>{setSortType({type:"rgf",order:sortType.type==="rgf"?sortType.order*-1:1})}}>
                {sortType.type==="rgf" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} RGF
              </th>
              <th className="name" onClick={()=>{setSortType({type:"name",order:sortType.type==="name"?sortType.order*-1:1})}}>
                {sortType.type==="name" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Nome
              </th>
              <th className="courses" onClick={()=>{setSortType({type:"courses",order:sortType.type==="courses"?sortType.order*-1:1})}}>
                {sortType.type==="courses" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Cursos
              </th>
              <th className="hc" onClick={()=>{setSortType({type:"workload_completed",order:sortType.type==="workload_completed"?sortType.order*-1:1})}}>
                {sortType.type==="workload_completed" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Horas Cumpridas
              </th>
              <th className="hj" onClick={()=>{setSortType({type:"justified_workload",order:sortType.type==="justified_workload"?sortType.order*-1:1})}}>
                {sortType.type==="justified_workload" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Horas Justificadas
              </th>
              <th className="ht" onClick={()=>{setSortType({type:"total_workload",order:sortType.type==="total_workload"?sortType.order*-1:1})}}>
                {sortType.type==="total_workload" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Horas Totais
              </th>
              <th className="rhtpf" onClick={()=>{setSortType({type:"processed",order:sortType.type==="processed"?sortType.order*-1:1})}}>
                {sortType.type==="processed" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Registro de HTPF no SGE
              </th>
              {id?<></>:<th className="caf">Cursos com atividade a Finalizar</th>}
            </tr>
          </thead>
          <tbody>
            {htpfDetail?.data?.sort(sortArrayRgf)?.sort(sortArrayEspec)?.map((htpf,index)=>(
              <tr key={htpf.idHtpfDetail} className={(htpfDetail.processedAt===null||htpfDetail.hasOwnProperty("processedAt")===false)?(htpf.total_workload<80?"alert":""):(htpf.processedAt?"":"alert")}>
                <td>{index+1}</td>
                <td>{htpf.rgf}</td>
                <td>{htpf.name}</td>
                <td>{htpf.courses.map((course)=>(<p key={course.idSubscription}>{course.title}</p>))}</td>
                <td>{htpf.workload_completed}</td>
                <td>{htpf.justified_workload}</td>
                <td className={htpf.processedAt?(htpf.total_workload<80?"mark":""):""}>{htpf.total_workload}</td>
                <td>{htpf.processedAt?`Processado em ${format(parseInt(htpf.processedAt), " dd/MM/yyyy ", {locale})}`:""}</td>
                {id?<></>:<td>{htpf.hasOpenCourse?"não será registrado htpf por ter atividade em aberto":""}</td>}
              </tr>
            ))}
          </tbody>
        </TableHtpf>
        
        {htpfPreview?.data?.filter((element)=>{return htpfDetail.data.findIndex((detail)=>{return detail.rgf===element.rgf})<0;}).length >0 && <ListItem border={true} 
                 roundborder={false} 
                 disablePointer={true} 
                 row={false} 
                 reduct={0} 
                 visible={true} 
                 selected={false} 
        >
          {htpfDetail.processedAt?"Não foi registrado no SGE por ter atividades em aberto":"Não será processado no SGE por ter atividades em aberto"}
          <TableHtpf>
            <thead>
              <tr key="headTable">
                <th className="number"></th>
                <th className="rgf" onClick={()=>{setSortType({type:"rgf",order:sortType.type==="rgf"?sortType.order*-1:1})}}>
                  {sortType.type==="rgf" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} RGF
                </th>
                <th className="name" onClick={()=>{setSortType({type:"name",order:sortType.type==="name"?sortType.order*-1:1})}}>
                  {sortType.type==="name" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Nome
                </th>
                <th className="courses" onClick={()=>{setSortType({type:"courses",order:sortType.type==="courses"?sortType.order*-1:1})}}>
                  {sortType.type==="courses" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Cursos
                </th>
                <th className="hc" onClick={()=>{setSortType({type:"workload_completed",order:sortType.type==="workload_completed"?sortType.order*-1:1})}}>
                  {sortType.type==="workload_completed" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Horas Cumpridas
                </th>
                <th className="hj" onClick={()=>{setSortType({type:"justified_workload",order:sortType.type==="justified_workload"?sortType.order*-1:1})}}>
                  {sortType.type==="justified_workload" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Horas Justificadas
                </th>
                <th className="ht" onClick={()=>{setSortType({type:"total_workload",order:sortType.type==="total_workload"?sortType.order*-1:1})}}>
                  {sortType.type==="total_workload" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Horas Totais
                </th>
                <th className="rhtpf" onClick={()=>{setSortType({type:"processed",order:sortType.type==="processed"?sortType.order*-1:1})}}>
                  {sortType.type==="processed" && (sortType.order>0 ? <FaSortAmountDownAlt fontSize={12} />:<FaSortAmountDown fontSize={12}/>)} Registro de HTPF no SGE
                </th>
              </tr>
            </thead>
            <tbody>
              {htpfPreview?.data?.filter((element)=>{return htpfDetail.data.findIndex((detail)=>{return detail.rgf===element.rgf})<0;}).sort(sortArrayRgf)?.sort(sortArrayEspec)?.map((htpf,index)=>(
                <tr key={htpf.idHtpfDetail} className={(htpfDetail.processedAt===null||htpfDetail.hasOwnProperty("processedAt")===false)?(htpf.total_workload<80?"alert":""):(htpf.processedAt?"":"alert")}>
                  <td>{index+1}</td>
                  <td>{htpf.rgf}</td>
                  <td>{htpf.name}</td>
                  <td>{htpf.courses.map((course)=>(<p key={course.idSubscription}>{course.title}</p>))}</td>
                  <td>{htpf.workload_completed}</td>
                  <td>{htpf.justified_workload}</td>
                  <td className={htpf.processedAt?(htpf.total_workload<80?"mark":""):""}>{htpf.total_workload}</td>
                  <td>{htpf.processedAt?`Processado em ${format(parseInt(htpf.processedAt), " dd/MM/yyyy ", {locale})}`:""}</td>
                </tr>
              ))}
            </tbody>
          </TableHtpf>
        </ListItem>}

        {!id && accessRight("htpf").SP_rights.can_generate && <>
          <ReadMoreButton onClick={()=>{
            setModal({open:true,
              text:(<><p>Deseja gerar o HTPF para aprovação?</p><p>(Preferencialmente gerar o htpf após ser realizada a conferência e correção de horas lançadas no sistema!)</p></>),
              confirm:true,
              process:()=>{gerarHtpf();},
              });
          }} text="Gerar HTPF para aprovação"/>
        </>}

        {id && accessRight("htpf").SP_rights.can_review && <>
          <ButtonBox>
            {!htpfDetail?.processedAt && <ReadMoreButton onClick={()=>{
              setModal({open:true,
                text:"Deseja deletar o HTPF?",
                confirm:true,
                process:()=>{deletarHtpf({data:{idhtpf:htpfDetail?.idhtpf}});},
                });
            }} text="Deletar HTPF"/>}

            {!htpfDetail?.reviewed && <ReadMoreButton onClick={()=>{
              setModal({open:true,
                text:"Deseja aprovar o HTPF?",
                confirm:true,
                process:()=>{aprovarHtpf({data:{idhtpf:htpfDetail?.idhtpf,reviewed:true}});}
                });
            }} text="Aprovar HTPF para Processamento"/>}
          </ButtonBox>
          {!htpfDetail?.processedAt && <>
            <p>Observação:</p>
            <p>Caso tenha correções de horas de htpf deletar este htpf acessar a preview e gerar um novo htpf com as horas atualizadas.</p>
          </>}
        </>}

        <Modal open={modal.open} close={()=>{}} buttonClose = {false} width={50} height={15}>
          <Message>{modal.text}</Message>
          {!modal.confirm && <ButtonBox>
            <ReadMoreButton onClick={()=>{modal.process()}} text="Ok"/>
          </ButtonBox>}
          {modal.confirm && <ButtonBox>
            <ReadMoreButton onClick={()=>{modal.process()}} text="Sim"/>
            <ReadMoreButton onClick={()=>{setModal({open:false,text:"",confirm:false,process:()=>{}})}} text="Não"/>
          </ButtonBox>}
        </Modal>

      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesHtpfDetail;