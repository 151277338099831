import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PDFViewer, PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';

import {
  Container,
  ContainerCertificate,
  DownloadButton,
} from "./styles";

import ContentBox from "../../components/UI/ContentBox";
import Banner from "../../components/UI/Banner";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton"
import FullScreenLoading from "../../components/UI/FullScreenLoading";

import useApi from "../../hooks/useApi";

import Certificate2024 from "./Template/Certificate2024";

import {icons} from "../../constants"

const CoursesCertificatePrint = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const MyDoc = () => (
    <Document>
      <Page>
        <Text>teste</Text>
      </Page>
    </Document>
  );
  
  const [certificate, setCertificate]= useState({
    idCertificate: "",
		idUserCertificate: "",
		courseTitle: "",
		name: "",
		cpf: "",
		totalWorkload: 0,
		hoursTaken: 0,
		hoursJustified: 0,
		courseBegin: new Date(),
		courseEnd: new Date(),
		createdAt: new Date(),
		updatedAt: "",
		canceled: null,
		reasonForCancellation: null,
		createdBy: ""
  });

  const [getCertificate, certificateInfo] = useApi({
    debounceDelay: 1000,
    url: "canalPedagogico/course/certificate",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        let certificate = response.data.find((value)=>{return value.canceled===false});
        if(certificate){
          console.log("certificado", certificate);
          setCertificate(certificate);
        }
      }
    },
  });

  useEffect(() => {
    getCertificate({params:{id}});
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Menu />
      {(certificateInfo.loading) && <FullScreenLoading />}
      <Banner>
        Certificado
      </Banner>
      <ContentBox>
        {certificate.idCertificate!==""?
          <>
            <ContainerCertificate>
              <PDFViewer width="100%" height="100%">
                <Certificate2024 certificate={certificate}/>
              </PDFViewer>
            </ContainerCertificate>
            <PDFDownloadLink document={<Certificate2024 certificate={certificate}/>} fileName={`${certificate.courseTitle.trim()}.pdf`}>
              {({ blob, url, loading, error }) =>
                loading ? <DownloadButton>Loading document...</DownloadButton> : <DownloadButton><img src={icons.btn_download} alt="download"/></DownloadButton>
              }
            </PDFDownloadLink>
          </>       
        :
        <>Certificado não localizado<br/></>
        }
        <ReadMoreButton text={"voltar"} onClick={()=>{navigate(-1);}}/>
      </ContentBox>

      <Footer />
    </Container>
  );
};

export default CoursesCertificatePrint;
