import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";
import * as XLSX from "xlsx";

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton";

import {
  Container,
  ListContent,
  ListItem,
  Table,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
// import { images } from "../../constants";

import useApi from "../../hooks/useApi";


const CoursesSubscriptionManagerList = () => {
  const [coursesList, setCoursesList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedList, setSelectedList] = useState("");
  const [downloadClick, setDownloadClick] = useState(false);
  const [openList, setOpenList] = useState(false);
  const navigate = useNavigate();

  const [year, setYear] = useState(null);
  const [listYear, setListYear] = useState([2024,2025]);
  const [alert, setAlert] = useState(null);

  const [getCourses, getCoursesInfo] = useApi({
    url: "canalPedagogico/course/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setCoursesList(response.data);
        setAlert(null);
        if(year!==null && response.data.length===0){
          setAlert(`Não foi localizado listas de inscritos para serem exibidos em ${year}`);
        }
      }
    },
  });

  const [getCoursesSubscription, getCoursesSubscriptionInfo] = useApi({
    url: "canalPedagogico/course/subscription/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log(response.data);
        setSubscriptionList(response.data);
      }
    },
  });

  const controlList = (typeChange=0, segmentChange=0)=>{
    let list = [];
    coursesList.forEach((type)=>{
      if(segmentChange!==0){
        if(type.courseType===typeChange){
          type.segments.forEach((segment)=>{
            if(segment.segment===segmentChange){
              if(segment.open){
                segment.open=false;
              }
              else{
                segment.open=true;
              }
            }
          })
        }
      }
      else{
        if(type.courseType===typeChange){
          if(type.open){
            type.open=false;
          }
          else{
            type.open=true;
          }
        }
      }
      list.push(type);
    })
    setCoursesList(list);
  };

  const download = (id,title) => {
    setDownloadClick(true);
    setSelectedList(title);
    getCoursesSubscription({params:{id}});
  }

  const open = (id,title) => {
    setOpenList(true);
    setSelectedList(title);
    getCoursesSubscription({params:{id}});
  }

  const handleDownload = () => {
    let maxWidth = [
      5,
      10,
      15,
      10,
      10,
      10
    ]

    subscriptionList.forEach((sub)=>{
      if(sub.rgf?.length>maxWidth[0])maxWidth[0]=sub.rgf.length;
      if(sub.name?.length>maxWidth[1])maxWidth[1]=sub.name.length;
      if(sub.cpf?.length>maxWidth[2])maxWidth[2]=sub.cpf.length;
      if(sub.email?.length>maxWidth[3])maxWidth[3]=sub.email.length;
      if(sub.unityName?.length>maxWidth[4])maxWidth[4]=sub.unityName.length;
      if(sub.className?.length>maxWidth[5])maxWidth[5]=sub.className.length;
    });

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById("tableCourses"));
    maxWidth.forEach((width)=>{worksheet['!cols'].push({ wch: width+2 })});
    // console.log(worksheet);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    XLSX.writeFile(workbook, `${selectedList.trim()}.xlsx`, { compression: true });
    setDownloadClick(false);
  };

  useEffect(() => {
    //getCourses();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(year!==null){
      getCourses({params:{year}});
    }
    //eslint-disable-next-line
  }, [year]);

  useEffect(() => {
    if(downloadClick){
      handleDownload();
    }
    //eslint-disable-next-line
  }, [subscriptionList]);

  return (
    <Container>
      {(getCoursesInfo.loading || 
       getCoursesSubscriptionInfo.loading) && <FullScreenLoading />}
      
      <Accessibility />
      <Menu />
      <Banner>GERENCIAMENTO DE LISTA DE INSCRITOS NOS CURSOS</Banner>
      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate(-1);}}
        >
          <MdArrowLeft fontSize={36}/> Voltar para gestão de formação
        </ListItem>

        {
          year===null && <>
            {listYear.map((item)=>(
              <ListItem row={true} border={true} key={item} onClick={()=>{setYear(item)}} visible={true} reduct={0} roundborder={true} selected={false}>
                {item}
              </ListItem>
            ))}
          </>
        }

        {
          alert && <>{alert}</>
        }

        {
          coursesList.map((type)=>(type.segments.length>0 &&
            <ListContent key={type.courseType} visible={!openList}>
              <ListItem key={type.courseType} onClick={()=>{controlList(type.courseType)}} visible={true} reduct={0} roundborder={true} selected={type.open}>{type.courseTypeName} {type.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
              {type.segments.map((segment)=>(
                <ListContent visible={type.open} key={segment.segment}>
                  {type.courseTypeName!=="Formação Complementar" && <ListItem key={segment.segment} onClick={()=>{controlList(type.courseType,segment.segment)}} visible={type.open} reduct={2} roundborder={true} selected={segment.open}>{segment.segmentName===null?"OUTROS":segment.segmentName} {segment.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>}
                  {segment.courses.map((course)=>(
                    <ListItem 
                      key={course.id} 
                      visible={(type.courseTypeName==="Formação Complementar")?type.open:segment.open} 
                      reduct={type.courseTypeName==="Formação Complementar"?2:4} 
                      roundborder={false}
                      disablePointer={true}
                      >
                      <div>
                        {course.title} 
                        <div className="buttonList">
                          {course.hasSubscription && <ReadMoreButton onClick={()=>{open(course.id,course.title); window.scrollTo(0, 0);}} text="Exibir Lista" />}
                          {course.hasSubscription && <ReadMoreButton onClick={()=>{download(course.id,course.title)}} text="Download xlsx" />}
                        </div>
                      </div>
                    </ListItem>
                  ))}
                </ListContent>))}
            </ListContent>))
        }

          <ListItem key={"Vizualizacao de lista"} onClick={()=>{setOpenList(false)}} visible={openList} reduct={0} roundborder={true} selected={true}> Fechar lista <MdArrowDropUp fontSize={36}/></ListItem>
            {<Table id="tableCourses" visible={openList}>
              <thead>
                <tr><th colSpan="6">{selectedList}</th></tr>
                <tr>
                  <th>RGF</th>
                  <th>Nome</th>
                  <th className='cpfClass'>CPF</th>
                  <th className='emailClass'>E-mail</th>
                  <th>Escola</th>
                  <th className='classeClass'>Turma</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionList.map((sub,index)=>(<tr key={index}>
                  <td>{sub.rgf}</td>
                  <td>{sub.name}</td>
                  <td>{sub.cpf}</td>
                  <td>{sub.email && sub.email}</td>
                  <td>{sub.unityName}</td>
                  <td>{sub.className}</td>
                </tr>))}
              </tbody>
            </Table>}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesSubscriptionManagerList;