import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdArrowLeft,
} from "react-icons/md";

import Accessibility from "../../components/UI/Accessibility";
import ContentBox from "../../components/UI/ContentBox";
import Footer from "../../components/UI/Footer";
import Menu from "../../components/UI/Menu";
import ReadMoreButton from "../../components/UI/ReadMoreButton";

import {
  Container,
  ListContent,
  ListItem,
} from "./styles";
import Banner from "../../components/UI/Banner";
import FullScreenLoading from "../../components/UI/FullScreenLoading";
// import { images } from "../../constants";
import ReportTable from "./Component/ReportTable";
import ReportDownload from "./Component/ReportDownload";

import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";


const CoursesReport = () => {
  const [coursesList, setCoursesList] = useState([]);
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [viewReport, setViewReport] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [listYear, setListYear] = useState([2024,2025]);

  const [getCourses, getCoursesInfo] = useApi({
    url: "canalPedagogico/course/byTerm/v2",
    method: "get",
    onCompleted: (response) => {
      if (!response.error) {
        console.log("courses",response.data);
        setCoursesList(response.data);
      }
    },
  });

  const controlList = (typeChange=0, segmentChange=0)=>{
    let list = [];
    coursesList.forEach((type)=>{
      if(segmentChange!==0){
        if(type.courseType===typeChange){
          type.segments.forEach((segment)=>{
            if(segment.segment===segmentChange){
              if(segment.open){
                segment.open=false;
              }
              else{
                segment.open=true;
              }
            }
          })
        }
      }
      else{
        if(type.courseType===typeChange){
          if(type.open){
            type.open=false;
          }
          else{
            type.open=true;
          }
        }
      }
      list.push(type);
    })
    setCoursesList(list);
  };

  const { user } = useAuth();
  const getEditorId = (type) => {
    let teste = user?.profiles[0]?.editor?.find(
      (item) =>
        item.category === "Formação Elementar" ||
        item.category === "Formação Complementar"
    );
    console.log("teste",teste);
    return teste?.id ? teste.id : false;
  };

  useEffect(() => {
    console.log("inicial",getEditorId());
    if(getEditorId()===false){
      if(user){
        navigate("/home2");
      }
      else{
        navigate("/login");
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(year!==null){
      getCourses({params:{hasSubscription:true, year}});
    }
    //eslint-disable-next-line
  }, [year]);

  return (
    <Container>
      {(getCoursesInfo.loading || 
       tableLoading) && <FullScreenLoading />}
      
      <Accessibility />
      <Menu />
      <Banner>RELATÓRIOS DOS CURSOS {year?year:""}</Banner>
      <ContentBox>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={true} 
                 selected={true} 
                 onClick={()=>{navigate(-1);}}
        >
          <MdArrowLeft fontSize={36}/> Voltar para gestão de formação
        </ListItem>
        <ListItem border={true} 
                 roundborder={true} 
                 pointer={true} 
                 row={true} 
                 reduct={0} 
                 visible={viewReport} 
                 selected={true} 
                 onClick={()=>{setViewReport(false); setId("");}}
        >
          Fechar Relatório
        </ListItem>
        {
          year===null && <>
            <ListContent key={"listYear"} visible={true}>
              {listYear.map((item)=>(
                <ListItem row={true} border={true} key={item} onClick={()=>{setYear(item)}} visible={true} reduct={0} roundborder={true} selected={false}>
                  {item}
                </ListItem>
            ))}
            </ListContent>
          </>
        }
        {
          !viewReport && coursesList.map((type)=>(type.segments.length>0 &&
            <ListContent key={type.courseType} visible={true}>
              <ListItem row={true} border={true} key={type.courseType} onClick={()=>{controlList(type.courseType)}} visible={true} reduct={0} roundborder={true} selected={type.open}>{type.courseTypeName} {type.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>
              {type.segments.map((segment)=>(
                <ListContent visible={type.open} key={segment.segment}>
                  {type.courseTypeName!=="Formação Complementar" && <ListItem key={segment.segment} row={true} onClick={()=>{controlList(type.courseType,segment.segment)}} visible={type.open} reduct={2} roundborder={true} border={true} selected={segment.open}>{segment.segmentName===null?"OUTROS":segment.segmentName} {segment.open?<MdArrowDropUp fontSize={36}/>:<MdArrowDropDown fontSize={36}/>}</ListItem>}
                  {segment.courses.map((course)=>(
                    <ListItem 
                      row={true} 
                      key={course.id} 
                      visible={(type.courseTypeName==="Formação Complementar")?type.open:segment.open} 
                      reduct={type.courseTypeName==="Formação Complementar"?2:4} 
                      border={true}
                      roundborder={false}
                      cursor={"auto"}>
                      <ListItem
                      reduct={30}
                      align={"flex-start"}
                      padW={4}
                      cursor={"auto"}
                      visible={(type.courseTypeName==="Formação Complementar")?type.open:segment.open} >
                        {course.title} 
                      </ListItem>
                      <ListItem
                      reduct={70}
                      justify={"space-between"}
                      height={11}
                      cursor={"auto"}
                      visible={(type.courseTypeName==="Formação Complementar")?type.open:segment.open} >
                        <ReadMoreButton onClick={()=>{setId(course.id);setViewReport(true);}} text="Abrir Relatório" />
                        <ReportDownload 
                          id={course.id}
                          loading={(load)=>{setTableLoading(load)}}
                        />
                      </ListItem>
                    </ListItem>
                  ))}
                </ListContent>))}
            </ListContent>))
        }

        {id!=="" && <ReportTable 
          id={id}
          tablevisible={viewReport}
          loading={(load)=>{setTableLoading(load)}}
        />}
      </ContentBox>
      <Footer />
    </Container>
  );
};

export default CoursesReport;