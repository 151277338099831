import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 100vh;
  color: var(--primary);
  background-color: var(--secondary);
`;

export const ListContent = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1rem;
`;

export const ListItem = styled.div`
  display: ${(props) => props.visible ? css`flex` : css`none`};
  flex-direction:${(props) => props.row ? css`row` : css`column`};
  align-items:center;
  justify-content:center;
  flex-wrap:nowrap;
  width: ${(props) => css`${100-props.reduct}%`};
  margin: 1rem 0 0 0;
  padding: 0 2rem;
  cursor: ${(props) => props.disablePointer ? css`auto` : css`pointer`};
  border: 1px solid var(--primary);
  border-radius: ${(props) => props.roundborder ? css`10rem` : css`0rem`};
  background-color: ${(props) =>
    props.selected ? css`var(--purple)` : css`var(--secondary)`};
  color: ${(props) =>
    props.selected ? css`var(--secondary)` : css`var(--primary)`};
  div{
    display: ${(props) => props.visible ? css`flex` : css`none`};
    width: 100%;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    padding: 0.8rem;
  }
  .info{
    display: ${(props) => props.visible ? css`flex` : css`none`};
    width: 60%;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    padding: 0.8rem;
  }
  .buttonList{
    display: ${(props) => props.visible ? css`flex` : css`none`};
    width: 35%;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    padding: 0.8rem;
  }
`;

export const Justify = styled.div`
  display: flex;
  align-items:center;
  flex-direction: column;
  width: 100rem;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  width: 100%;
`;

export const TableHtpf = styled.table`
  word-wrap: normal;
  text-align:center;
  border-collapse: collapse;
  font-size: 13px;
  margin: 0.5rem;
  border: 1px solid var(--primary);

  th, td {
    border: 1px solid var(--primary);
    padding: 2px 4px;
  }

  .mark{
    background-color: var(--softGray);
    color: var(--hardRed);
    font-weight: 800;
  }

  .alert {
    background-color: var(--lightRed);
    color: var(--primary);
    font-weight: 800;
  }
  .hc, .ht, .hj, .rgf {
    min-width:90px;
    max-width:90px;
  }
  .name, .courses {
    min-width:300px;
    max-width:600px;
  }
  .rhtpf{
    min-width:100px;
    max-width:100px;
  }
  .caf{
    min-width:100px;
    max-width:100px;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:space-around;
  width: 80rem;
`;
